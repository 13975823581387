<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col class="py-0">
        <Materials 
          :materials="materialsAdmin"
          :materialStandards="materialStandards"
          :unsCategories="unsCategories"
          :unsAlloyGroups="unsAlloyGroups"
          :sawCalcGroups="sawCalcGroups"
          :unsGeneralDescriptions="unsGeneralDescriptions"
          :subCategories="subCategories"
          :dataLoading="materialsLoading"
          @materialsCleanup="materialsCleanup"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ListItemDataTable 
          :title="$t('admin.materials.materialStandardsLabel')"
          :items="materialStandards"
          :itemsPerPage="5"
          :dataLoading="materialStandardsLoading"
          @save="saveMaterialStandard"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ListItemDataTable 
          :title="$t('admin.materials.unsCategoriesLabel')"
          :items="unsCategories"
          :itemsPerPage="5"
          :dataLoading="unsCategoriesLoading"
          @save="saveUNSCategory"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ListItemDataTable 
          :title="$t('admin.materials.unsAlloyGroupsLabel')"
          :items="unsAlloyGroups"
          :itemsPerPage="5"
          :dataLoading="unsAlloyGroupsLoading"
          @save="saveUNSAlloyGroup"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <SawCalcGroupDataTable 
          :title="$t('admin.materials.sawCalcGroupsLabel')"
          :items="sawCalcGroups"
          :itemsPerPage="5"
          :dataLoading="sawCalcGroupsLoading"
          @save="saveSawCalcGroup"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ListItemDataTable 
          :title="$t('admin.materials.unsGeneralDescriptionsLabel')"
          :items="unsGeneralDescriptions"
          :itemsPerPage="5"
          :dataLoading="unsGeneralDescriptionsLoading"
          @save="saveUNSGeneralDescription"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <ListItemDataTable 
          :title="$t('admin.materials.subCategoriesLabel')"
          :items="subCategories"
          :itemsPerPage="5"
          :dataLoading="subCategoriesLoading"
          @save="saveSubCategory"
        />
      </v-col>
    </v-row>
    <v-overlay
      :absolute="true"
      :value="materialsLoading"
      >
      <v-progress-circular indeterminate size="32"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import Materials from "@/components/admin/Material/Materials";
import ListItemDataTable from "@/components/common/ListItemDataTable";
import SawCalcGroupDataTable from "@/components/admin/Material/SawCalcGroupDataTable";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "MaterialsAdmin",

  components: {
    Materials,
    ListItemDataTable,
    SawCalcGroupDataTable,
  },

  data() {
    return {
      materialStandardsLoading: false,
      unsCategoriesLoading: false,
      unsAlloyGroupsLoading: false,
      sawCalcGroupsLoading: false,
      unsGeneralDescriptionsLoading: false,
      subCategoriesLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "materialsAdmin",
      "materialsLoading",
      "materialStandards",
      "unsCategories",
      "unsAlloyGroups",
      "sawCalcGroups",
      "unsGeneralDescriptions",
      "subCategories",
    ]),
  },

  methods: {
    getAdminMatrialsData() {
      this.$store.dispatch("getAdminMatrialsData").then(
        () => {},
        (error) => {
          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    saveMaterialStandard(item) {
      if (!item) {
        return;
      }

      const value = item.value;
      if (!value.includes("-")) {
        alert(this.$t("admin.materials.materialStandardsValidation"));
        return;
      }

      let functionName = "";

      if (item.id == undefined || item.id == 0) {
        functionName = "createMaterialStandard";
      } else {
        functionName = "updateMaterialStandard";
      }

      this.materialStandardsLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.materialStandardsLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getMaterialStandards");
        },
        (error) => {
          this.materialStandardsLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    saveUNSCategory(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.id == undefined || item.id == 0) {
        functionName = "createUNSCategory";
      } else {
        functionName = "updateUNSCategory";
      }

      this.unsCategoriesLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.unsCategoriesLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getUNSCategories");
        },
        (error) => {
          this.unsCategoriesLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    saveUNSAlloyGroup(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.id == undefined || item.id == 0) {
        functionName = "createUNSAlloyGroup";
      } else {
        functionName = "updateUNSAlloyGroup";
      }

      this.unsAlloyGroupsLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.unsAlloyGroupsLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getUNSAlloyGroups");
        },
        (error) => {
          this.unsAlloyGroupsLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    saveSawCalcGroup(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.sawCalcGroupID == undefined || item.sawCalcGroupID == 0) {
        functionName = "createSawCalcGroup";
      } else {
        functionName = "updateSawCalcGroup";
      }

      this.sawCalcGroupsLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.sawCalcGroupsLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getSawCalcGroups");
        },
        (error) => {
          this.sawCalcGroupsLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    saveUNSGeneralDescription(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.id == undefined || item.id == 0) {
        functionName = "createUNSGeneralDescription";
      } else {
        functionName = "updateUNSGeneralDescription";
      }

      this.unsGeneralDescriptionsLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.unsGeneralDescriptionsLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getUNSGeneralDescriptions");
        },
        (error) => {
          this.unsGeneralDescriptionsLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    saveSubCategory(item) {
      if (!item) {
        return;
      }

      let functionName = "";

      if (item.id == undefined || item.id == 0) {
        functionName = "createSubCategory";
      } else {
        functionName = "updateSubCategory";
      }

      this.subCategoriesLoading = true;

      this.$store.dispatch(functionName, item).then(
        (response) => {
          this.subCategoriesLoading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("getSubCategories");
        },
        (error) => {
          this.subCategoriesLoading = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    materialsCleanup() {
      this.getAdminMatrialsData();
    },
  },

  mounted() {
    this.getAdminMatrialsData();
  },
};
</script>
