<template>
  <v-data-table id="content"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :search="search"
    sort-by="orderInList"
    :loading="dataLoading || loading" 
    :loading-text="$t('admin.listItemDataTable.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title><h2>{{ title }}</h2></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.listItemDataTable.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="addItem()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog 
          v-model="dialog" 
          persistent 
          :fullscreen="$vuetify.breakpoint.smAndDown" 
          max-width="520px">
          <ListItemDialog
            :item="editedItem"
            @cancel="cancel"
            @save="save"
            />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <span>{{ $t('admin.listItemDataTable.noRecordsFound') }}</span>
    </template>
  </v-data-table>
</template>

<script>
import ListItemDialog from "@/components/common/ListItemDialog";

export default {
  name: "ListItemDataTable",

  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },

  components: {
    ListItemDialog,
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("admin.listItemDataTable.itemLabel"),
          value: "value",
        },
        {
          text: this.$t("admin.listItemDataTable.orderInListLabel"),
          value: "orderInList",
        },
        {
          text: this.$t("admin.listItemDataTable.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      search: "",
      dialog: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        value: "",
        orderInList: 0,
      },
      defaultItem: {
        id: 0,
        value: "",
        orderInList: 0,
      },
      loading: false,
    };
  },

  methods: {
    addItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });

      this.$emit("cancel");
    },
    save(item) {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });

      this.$emit("save", item);
    },
  },
};
</script>