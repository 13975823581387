<template>
  <v-card>
    <v-card-title class="card-title">
      <h3>
        {{ $t("admin.dataTableChemicalCompositions.title") }}
      </h3>
    </v-card-title>

    <v-card-text>
      <v-container
        class="pa-0"
        :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }"
      >
        <v-row>
          <v-col>
            <v-data-table
              id="content"
              :headers="headers"
              :items="items"
              :search="search"
              sort-by="text"
              :items-per-page="5"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('admin.dataTableChemicalCompositions.search')"
                    single-line
                    hide-details
                    style="max-width: 300px;"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.minPercent="{ item }">
                <v-text-field
                  v-model="item.minPercent"
                  style="max-width: 50px;"
                  type="number"
                ></v-text-field>
              </template>
              <template v-slot:item.maxPercent="{ item }">
                <v-text-field
                  v-model="item.maxPercent"
                  style="max-width: 50px;"
                  type="number"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions
      :class="{
        'padding-action-small': $vuetify.breakpoint.smAndDown,
        'padding-action': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-container class="px-1 py-0">
        <v-row>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('admin.dataTableChemicalCompositions.cancelButton')"
              class="primary white--text full-width"
              :disabled="dataTableChemicalCompositionsDialog.loading"
              :loading="dataTableChemicalCompositionsDialog.loading"
              @click="cancel"
            ></v-btn>
          </v-col>
          <v-col class="pt-0">
            <v-btn
              depressed
              tile
              v-html="$t('admin.dataTableChemicalCompositions.updateButton')"
              class="primary white--text full-width"
              :disabled="dataTableChemicalCompositionsDialog.loading"
              :loading="dataTableChemicalCompositionsDialog.loading"
              @click="save"
            ></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DataTableChemicalCompositions",

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("admin.dataTableChemicalCompositions.elementLabel"),
          value: "elementDescription",
        },
        {
          text: this.$t("admin.dataTableChemicalCompositions.minPercentLabel"),
          value: "minPercent",
        },
        {
          text: this.$t("admin.dataTableChemicalCompositions.maxPercentLabel"),
          value: "maxPercent",
        },
      ],
      search: "",
    };
  },

  computed: {
    ...mapGetters(["dataTableChemicalCompositionsDialog"]),
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$emit("save", this.items);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title {
  padding: 20px 20px 0px 20px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 20px 0px 10px;
}

.padding-input {
  padding: 0 10px;
}

.padding-action {
  padding: 0px 20px 20px 20px;
}

.padding-action-small {
  padding: 5px 20px 10px 20px;
}

.v-btn {
  max-height: 30px;
  min-height: 23px;
}
</style>
