<template>
  <div>
    <v-card outlined>
      <v-form ref="itemForm">
        <v-card-text class="pa-0 px-3">
          <v-container fluid class="pa-0 px-3">
            <v-row>
              <v-overlay :absolute="true" :value="itemOverlay">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </v-overlay>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  autofocus
                  :label="$t('admin.materials.designationLabel')"
                  :disabled="itemOverlay"
                  v-model="item.designation"
                  :rules="itemInputRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-autocomplete
                  :label="$t('admin.materials.standardLabel')"
                  :items="materialStandards"
                  item-text="value"
                  item-value="id"
                  :disabled="itemOverlay"
                  v-model="item.materialStandardID"
                  @change="materialStandardChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  :label="$t('admin.materials.unsLabel')"
                  :disabled="itemOverlay"
                  v-model="item.uns"
                  :rules="itemInputRules"
                  @input="unsChanged"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="9" class="py-0">
                <v-autocomplete
                  :label="$t('admin.materials.unsCategoryLabel')"
                  :items="unsCategories"
                  item-text="value"
                  item-value="id"
                  :disabled="itemOverlay"
                  v-model="item.unsCategoryID"
                  @change="unsCategoryChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-autocomplete
                  :label="$t('admin.materials.unsAlloyGroupLabel')"
                  :items="unsAlloyGroups"
                  item-text="value"
                  item-value="id"
                  :disabled="itemOverlay"
                  v-model="item.unsAlloyGroupID"
                  @change="unsAlloyGroupChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-autocomplete
                  :label="$t('admin.materials.sawCalcGroupLabel')"
                  :items="sawCalcGroups"
                  item-text="sawCalcGroupName"
                  item-value="sawCalcGroupID"
                  :disabled="itemOverlay"
                  v-model="item.sawCalcGroupID"
                  @change="sawCalcGroupChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  :label="$t('admin.materials.compositeUNSLabel')"
                  :disabled="true"
                  v-model="item.compositeUNS"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-autocomplete
                  :label="$t('admin.materials.unsGeneralDescriptionLabel')"
                  :items="unsGeneralDescriptions"
                  item-text="value"
                  item-value="id"
                  :disabled="itemOverlay"
                  v-model="item.unsGeneralDescriptionID"
                  @change="unsGeneralDescriptionChanged"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="9" class="py-0">
                <v-autocomplete
                  :label="$t('admin.materials.subCategoryLabel')"
                  :items="subCategories"
                  item-text="value"
                  item-value="id"
                  :disabled="itemOverlay"
                  v-model="item.subCategoryID"
                  @change="subCategoryChanged"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  :label="$t('admin.materials.defaultHBHardnessLabel')"
                  :disabled="true"
                  v-model="item.defaultHBHardness"
                  :rules="itemInputRules"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" sm="2" class="px-2 pt-5">
                <span class="card-text-caption">
                  {{ $t("admin.materials.quickSearchLabel") }}
                </span>
              </v-col>
              <v-col cols="7" sm="7" class="px-2 pt-5">
                <v-btn-toggle
                  depressed
                  tile
                  dark
                  v-model="item.quickSearch"
                  mandatory
                  dense
                >
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.noLabel") }}
                    </span>
                  </v-btn>
                  <v-btn color="primary" :disabled="itemOverlay">
                    <span>
                      {{ $t("sawCalc.inputMachine.yesLabel") }}
                    </span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  :label="$t('admin.materials.orderInListLabel')"
                  :disabled="itemOverlay"
                  v-model="item.orderInList"
                  type="number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.machines.cancelButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="itemOverlay"
                  @click="close()"
                ></v-btn>
              </v-col>
              <v-col cols="12" sm="6" class="px-1 pt-0" align="right">
                <v-btn
                  depressed
                  tile
                  v-html="$t('admin.machines.saveButton')"
                  class="primary white--text full-width"
                  :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                  :disabled="saveDisabled"
                  @click="save(item)"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AdminMaterialDialog",

  props: {
    materialStandards: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsAlloyGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    sawCalcGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsGeneralDescriptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    subCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      itemOverlay: false,
      itemInputRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("sawCalc.inputMachine.inputRules"),
      ],
    };
  },

  computed: {
    itemValid() {
      if (!this.item) {
        return false;
      }

      let valid =
        this.item != null &&
        this.item.designation != undefined &&
        this.item.designation != "" &&
        this.item.materialStandardID != null &&
        this.item.materialStandardID > 0 &&
        this.item.uns != undefined &&
        this.item.uns != "" &&
        this.item.unsCategoryID != null &&
        this.item.unsCategoryID > 0 &&
        this.item.unsAlloyGroupID != null &&
        this.item.unsAlloyGroupID > 0 &&
        this.item.sawCalcGroupID != null &&
        this.item.sawCalcGroupID > 0 &&
        this.item.compositeUNS != undefined &&
        this.item.compositeUNS != "" &&
        this.item.defaultHBHardness != null &&
        this.item.defaultHBHardness > 0;

      return valid;
    },
    saveDisabled() {
      if (!this.itemValid || this.itemOverlay) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    materialStandardChanged(item) {
      if (item == undefined) {
        this.item.materialStandard = null;
        return;
      }

      const materialStandard = this.materialStandards.find(
        ({ id }) => id === item
      );

      this.item.materialStandard = materialStandard.value;
    },
    unsChanged() {
      this.updateCompositeUNS();
    },
    unsCategoryChanged(item) {
      if (item == undefined) {
        this.item.unsCategory = null;
        return;
      }

      const unsCategory = this.unsCategories.find(({ id }) => id === item);

      this.item.unsCategory = unsCategory.value;
    },
    unsAlloyGroupChanged(item) {
      if (item == undefined) {
        this.item.unsAlloyGroup = null;
        return;
      }

      const unsAlloyGroup = this.unsAlloyGroups.find(({ id }) => id === item);

      this.item.unsAlloyGroup = unsAlloyGroup.value;
    },
    sawCalcGroupChanged(item) {
      if (item == undefined) {
        this.item.sawCalcGroup = null;
        return;
      }

      const sawCalcGroup = this.sawCalcGroups.find(
        ({ sawCalcGroupID }) => sawCalcGroupID === item
      );

      this.item.sawCalcGroupName = sawCalcGroup.sawCalcGroupName;
      this.item.sawCalcGroupDescription = sawCalcGroup.sawCalcGroupDescription;
      this.item.machinabilityGroupBiMetal =
        sawCalcGroup.machinabilityGroupBiMetal;
      this.item.machinabilityGroupCarbide =
        sawCalcGroup.machinabilityGroupCarbide;
      this.item.machinabilityRating = sawCalcGroup.machinabilityRating;
      this.item.defaultHBHardness = sawCalcGroup.defaultHBHardness;

      this.updateCompositeUNS();
    },
    unsGeneralDescriptionChanged(item) {
      if (item == undefined) {
        this.item.unsGeneralDescription = null;
        return;
      }

      const unsGeneralDescription = this.unsGeneralDescriptions.find(
        ({ id }) => id === item
      );

      this.item.unsGeneralDescription = unsGeneralDescription.value;
    },
    subCategoryChanged(item) {
      if (item == undefined) {
        this.item.subCategory = null;
        return;
      }

      const subCategory = this.subCategories.find(({ id }) => id === item);

      this.item.subCategory = subCategory.value;
    },
    updateCompositeUNS() {
      this.item.compositeUNS =
        this.item.uns + " / " + this.item.sawCalcGroupName;
    },
    save() {
      this.itemOverlay = true;

      let functionName = "";

      if (!this.item.id) {
        functionName = "createMaterial";
      } else {
        functionName = "updateMaterial";
      }

      this.$store.dispatch(functionName, this.item).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };
          this.$store.dispatch("showSnackbar", notification);

          this.$refs.itemForm.reset();
          this.$emit("materialsUpdated", false);
        },
        (error) => {
          this.itemOverlay = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }
          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };
          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
