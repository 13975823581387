<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="materials"
    :search="searchTrigger"
    sort-by="designation"
    :loading="dataLoading || loading"
    :loading-text="$t('admin.materials.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.materials.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.materials.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="showSettings()"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn text icon color="primary" :disabled="loading" @click="addItem()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="materialsCleanup()"
        >
          <v-icon>mdi-database-refresh</v-icon>
        </v-btn>
        <DataTableSettings
          :items="dataTableItems"
          @updateSettings="updateSettings($event)"
          :key="dataTableSettingsKey"
        />

        <v-dialog
          v-model="materialDialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="650px"
        >
          <MaterialDialog
            :materialStandards="materialStandards"
            :unsCategories="unsCategories"
            :unsAlloyGroups="unsAlloyGroups"
            :sawCalcGroups="sawCalcGroups"
            :unsGeneralDescriptions="unsGeneralDescriptions"
            :subCategories="subCategories"
            :item="editedItem"
            @close="closeMaterialDialog"
            @materialsUpdated="materialsUpdated"
            :key="materialDialogKey"
          />
        </v-dialog>

        <v-dialog
          v-model="chemicalCompositionsDialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          max-width="650px"
        >
          <DataTableChemicalCompositions
            :items="chemicalCompositions"
            @cancel="cancelChemicalCompositionsDialog"
            @save="saveChemicalCompositions"
            :key="chemicalCompositionsDialogKey"
          />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.quickSearch="{ item }">
      <v-icon v-show="item.quickSearch">
        mdi-check
      </v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.chemicalComposition="{ item }">
      <v-btn
        depressed
        tile
        v-html="$t('admin.materials.chemicalCompositionButton')"
        class="primary white--text"
        small
        :disabled="loading"
        :loading="loading"
        @click="openChemicalComposition(item)"
      ></v-btn>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.materials.noRecordsFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableSettings from "@/components/common/DataTableSettings";
import MaterialDialog from "@/components/admin/Material/MaterialDialog";
import DataTableChemicalCompositions from "@/components/admin/Material/DataTableChemicalCompositions";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "MaterialsAdmin",

  props: {
    materials: {
      type: Array,
      required: true,
      default: () => [],
    },
    materialStandards: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsAlloyGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    sawCalcGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    unsGeneralDescriptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    subCategories: {
      type: Array,
      required: true,
      default: () => [],
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },

  components: {
    DataTableSettings,
    MaterialDialog,
    DataTableChemicalCompositions,
  },

  data() {
    return {
      headersList: [
        {
          text: this.$t("admin.materials.designationLabel"),
          value: "designation",
        },
        {
          text: this.$t("admin.materials.standardLabel"),
          value: "materialStandard",
        },
        {
          text: this.$t("admin.materials.unsLabel"),
          value: "uns",
        },
        {
          text: this.$t("admin.materials.unsCategoryLabel"),
          value: "unsCategory",
        },
        {
          text: this.$t("admin.materials.unsAlloyGroupLabel"),
          value: "unsAlloyGroup",
        },
        {
          text: this.$t("admin.materials.sawCalcGroupLabel"),
          value: "sawCalcGroupName",
        },
        {
          text: this.$t("admin.materials.compositeUNSLabel"),
          value: "compositeUNS",
        },
        {
          text: this.$t("admin.materials.defaultHBHardnessLabel"),
          value: "defaultHBHardness",
        },
        {
          text: this.$t("admin.materials.machinabilityGroupBiMetalLabel"),
          value: "machinabilityGroupBiMetal",
        },
        {
          text: this.$t("admin.materials.machinabilityGroupCarbideLabel"),
          value: "machinabilityGroupCarbide",
        },
        {
          text: this.$t("admin.materials.machinabilityRatingLabel"),
          value: "machinabilityRating",
        },
        {
          text: this.$t("admin.materials.unsGeneralDescriptionLabel"),
          value: "unsGeneralDescription",
        },
        {
          text: this.$t("admin.materials.subCategoryLabel"),
          value: "subCategory",
        },
        {
          text: this.$t("admin.materials.quickSearchLabel"),
          value: "quickSearch",
        },
        {
          text: this.$t("admin.materials.chemicalCompositionLabel"),
          value: "chemicalComposition",
        },
        {
          text: this.$t("admin.materials.edit"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
      search: "",
      materialDialog: false,
      editedIndex: -1,
      editedItem: {
        materialID: 0,
        designation: "",
      },
      defaultItem: {
        materialID: 0,
        designation: "",
      },
      loading: false,
      dataTableItems: [],
      chemicalCompositionsDialog: false,
      chemicalCompositions: [],
      originalChemicalCompositions: [],
    };
  },

  computed: {
    ...mapGetters([
      "materialsAdminSettings",
      "dataTableSettingsKey",
      "materialDialogKey",
      "chemicalCompositionsDialogKey",
    ]),
    searchTrigger() {
      if (this.search.length >= 3) {
        return this.search;
      } else {
        return "";
      }
    },
  },

  methods: {
    getAdminMaterials() {
      this.loading = true;

      this.$store.dispatch("getAdminMaterials").then(
        (response) => {
          this.loading = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveMaterialsAdminSettings", params);

      this.updateHeadersFromSettings(params.headers);
    },
    updateHeaders() {
      if (this.settings()) {
        this.updateHeadersFromSettings(this.materialsAdminSettings.headers);
      } else {
        this.headers = [];
        this.headers = [...this.headersList];
      }
    },
    updateHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.dataTableItems = this.materialsAdminSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text + " (" + o.value + ")",
          value: o.value,
          selected: true,
        }));

        items.sort(function(a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadMaterialsAdminSettingsComponent");
    },
    settings() {
      return (
        this.materialsAdminSettings &&
        this.materialsAdminSettings.headers != null
      );
    },
    addItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.materialDialog = true;
    },
    editItem(item) {
      this.editedIndex = this.materials.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.quickSearch) {
        this.editedItem.quickSearch = 1;
      } else {
        this.editedItem.quickSearch = 0;
      }

      this.materialDialog = true;
      this.$store.dispatch("reloadMaterialDialogComponent");
    },
    deleteItem(item) {
      const action = this.$t("admin.materials.deleteRecord");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("deleteMaterial", { material: item }).then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.materialsUpdated();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    closeMaterialDialog() {
      this.materialDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    materialsUpdated() {
      this.materialDialog = false;
      this.getAdminMaterials();
    },
    materialsCleanup() {
      this.itemOverlay = true;

      this.$store.dispatch("materialsCleanup").then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.$emit("materialsCleanup");
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    openChemicalComposition(item) {
      this.editedIndex = this.materials.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.getChemicalCompositions(item.id);
    },
    cancelChemicalCompositionsDialog() {
      this.chemicalCompositionsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveChemicalCompositions(items) {
      const list = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.minPercent != undefined && item.minPercent <= 0) {
          item.minPercent = null;
        }

        if (item.minPercent != undefined && item.minPercent > 100) {
          item.minPercent = 100;
        }

        if (item.maxPercent != undefined && item.maxPercent <= 0) {
          item.maxPercent = null;
        }

        if (item.maxPercent != undefined && item.maxPercent > 100) {
          item.maxPercent = 100;
        }

        let original = this.originalChemicalCompositions[i];

        if (
          item.minPercent != original.minPercent ||
          item.maxPercent != original.maxPercent
        ) {
          list.push(item);
        }
      }

      this.chemicalCompositionsDialog = false;
      this.updateChemicalCompositions(this.editedItem.id, list);

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    updateChemicalCompositions(id, list) {
      this.loading = true;

      this.$store
        .dispatch("updateChemicalCompositions", { id: id, list: list })
        .then(
          (response) => {
            this.loading = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.loading = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            if (api.authenticationError(error.code)) {
              setTimeout(function() {
                router.push({ name: "Home" }).catch(() => {});
              }, api.authErrorRedirectTimeout);
            }
          }
        );
    },
    getChemicalCompositions(id) {
      this.loading = true;

      this.$store.dispatch("getChemicalCompositions", id).then(
        (response) => {
          this.chemicalCompositions = response.list;
          this.copyChemicalCompositions(this.chemicalCompositions);

          this.loading = false;
          this.chemicalCompositionsDialog = true;
          this.$store.dispatch("reloadChemicalCompositionsDialogComponent");

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function() {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    copyChemicalCompositions(items) {
      this.originalChemicalCompositions = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const original = JSON.parse(JSON.stringify(item));
        this.originalChemicalCompositions.push(original);
      }
    },
  },

  mounted() {
    this.updateHeaders();
  },
};
</script>
